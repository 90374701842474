import {useRef, useState} from 'react'
import {motion } from 'framer-motion'


export default function Framer({children}) {

    const ref = useRef(null)
    const [position, setPosition] = useState({x:0, y:0})

    const handleMouseMove = (e) => {
        const {clientX, clientY} = e
        const { width, height, left, top} = ref.current.getBoundingClientRect()
       const middleX = clientX - (left + width / 2)
         const middleY = clientY - (top + height / 2)
        setPosition({x: middleX *0.07, y: middleY * 0.07})
    }

    const reset = () => {
        setPosition({x:0, y:0})
    }

    const {x, y} = position


    return(
        <motion.div
            ref={ref}
            style={{position: 'relative'}}
            onMouseMove={handleMouseMove}
            onMouseLeave={reset}
            animate={{x, y}}
            transition={{type: 'spring', stiffness: 350, damping: 5, mass : 0.5}}    
        >
            {children}
        </motion.div>
    )

}