import React from 'react'
import '../style/header.scss'
import { forwardRef } from 'react'
import Magnetic from './Magnetic'

const Header = forwardRef(function Header(props, ref) {

    return (
        <div className='header'>
            <Magnetic>
                <div  className='burger'>
                    <div ref={ref} className='bounds'></div>
                </div>
            </Magnetic>
        </div>
    )
})
//  <div className='test'></div>

export default Header
