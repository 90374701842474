import React, { useEffect, useState } from 'react'
import '../style/stickyCursor.scss'
import { m, motion, transform, useMotionValue, useSpring } from 'framer-motion'

function StickyCursor({ stickyElement }) {

    const [cursorVisible, setCursorVisible] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const cursorSize = isHovered ? 60 : 20;
    const cursorBorderRadius = isHovered ? '16px' : '50px';



    const mouse = {
        x: useMotionValue(0),
        y: useMotionValue(0)
    }

    const smoothOptions = { stiffness: 300, damping: 20, mass: 0.5 }
    const smoothMouse = {
        x: useSpring(mouse.x, smoothOptions),
        y: useSpring(mouse.y, smoothOptions)
    }


    const scale = {
        x: useMotionValue(1),
        y: useMotionValue(1)
    }

    const skew = {
        x: useMotionValue(0),
        y: useMotionValue(0)
    }


    function manageMouseMove(e) {
        const { clientX, clientY } = e
        const { left, top, width, height } = stickyElement.current.getBoundingClientRect()

        const center = {
            x: left + width / 2,
            y: top + height / 2
        }

        const distance = {
            x: clientX - center.x,
            y: clientY - center.y
        }


        if (isHovered) {
            // strech cursor
            // const absDistance = Math.max(Math.abs(distance.x), Math.abs(distance.y))
            const newScaleX = transform(Math.abs(distance.x), [0, width / 2], [1, 1.2])
            const newScaleY = transform(Math.abs(distance.y), [0, height / 2], [1, 1.2])
            scale.x.set(newScaleX)
            scale.y.set(newScaleY)

            // skew cursor
            let valueMax = 10


        //    tests

        // skew.x.set(transform(distance.x,
                //     // ------------------------------------ range
                //     //si en bas
                //     distance.y > 0 ?
                //         // si en bas à droite
                //         distance.x > 0 ? [0, width / 2]
                //             :
                //             //si en bas à gauche
                //             [-width / 2, 0]
    
                //         :
                //         //si on est en  haut
                //         // si en haut à droite
                //         distance.x > 0 ? [0, width / 2]
    
                //             :
                //             //si en haut à gauche
                //             [-width / 2, 0]
    
                //     ,
                //     // ------------------------------------ skew
                //     //si en bas
                //     distance.y > 0 ?
                //         // si en bas à droite
                //         distance.x > 0 ? [0, valueMax]
                //             :
                //             //si en bas à gauche
                //             [-valueMax, 0]
    
                //         :
                //         //si en haut en bas
                //         // si en haut à droite
                //         distance.x > 0 ? [0, -valueMax]
    
                //             :
                //             //si en haut à gauche
                //             [valueMax, 0]
                // ))

            // console.log('distance.x', distance.x)
            
            // console.log('distance.y', distance.y)

            const absDistance = Math.min(Math.abs(distance.x), Math.abs(distance.y))
            console.log('absDistance.x', absDistance)

            if (distance.x < 0 && distance.y > 0) {
                skew.x.set(transform(absDistance, [0, width/2],[0, -valueMax]))
                // skew.y.set(transform(absDistance, [0, width/2],[0, -valueMax]))
                skew.y.set(0)
            }
            if (distance.x < 0  && distance.y < 0) {
                skew.y.set(0)
                skew.x.set(transform(absDistance, [0, width/2],[0, valueMax]))
            }
            if (distance.x > 0 && distance.y > 0) {
                skew.x.set(transform(absDistance, [0, width/2],[0, valueMax]))
                skew.y.set(0)
            }
            if (distance.x > 0 && distance.y < 0) {
                skew.y.set(0)
                skew.x.set(transform(absDistance, [0, width/2],[0, -valueMax]))
            }


            // skew.y.set(transform(distance.y,
            //     // ------------------------------------ range
            //     //si en bas

            //     distance.y > 0 ?

            //         // // si en bas à droite
            //         // distance.x > 0 ? [height / 2 , 0]
            //         //     :
            //         //     //si en bas à gauche
            //         //     [height / 2, 0]
            //         // :
            //          // si en bas à droite
            //         [height / 2, 0]
            //      :
            //         //si on est en  haut
            //         // si en haut à droite
            //         distance.x > 0 ? [-height / 2, 0]
            //             :
            //             //si en haut à gauche
            //             [-height / 2, 0]
            //     ,
            //     // ------------------------------------ skew
            //     //si en bas
            //     distance.y > 0 ?

            //         // si en bas à droite
            //         distance.x > 0 ? [0 , valueMax]
            //             :
            //             //si en bas à gauche
            //             [0, -valueMax]
            //         :
            //         //si on est en  haut

            //         // si en haut à droite
            //         distance.x > 0 ? [-valueMax, 0]
            //             :
            //             //si en haut à gauche
            //             [valueMax, 0]
            // ))

            mouse.x.set((center.x - cursorSize / 2) + distance.x * 0.2)
            mouse.y.set((center.y - cursorSize / 2) + distance.y * 0.2)

        } else {
            mouse.x.set(clientX - cursorSize / 2)
            mouse.y.set(clientY - cursorSize / 2)

            scale.x.set(1)
            scale.y.set(1)
            skew.x.set(0)
            skew.y.set(0)
        }
    }

    function manageMouseOver() {
        setIsHovered(true)
        const divBurger = document.querySelector('.burger')
        divBurger.classList.add('hovered')
    }

    function manageMouseLeave() {
        setIsHovered(false)
        const divBurger = document.querySelector('.burger')
        divBurger.classList.remove('hovered')
    }

    const handleMouseEnter = () => setCursorVisible(true);
    const handleMouseLeave = () => setCursorVisible(false);

    useEffect(() => {
        document.addEventListener('mouseover', handleMouseEnter);
            document.addEventListener('mouseout', handleMouseLeave);
        window.addEventListener('mousemove', manageMouseMove)
        //stickyElement
        stickyElement.current.addEventListener('mouseenter', manageMouseOver)
        stickyElement.current.addEventListener('mouseleave', manageMouseLeave)

        return () => {
            document.addEventListener('mouseover', handleMouseEnter);
            document.addEventListener('mouseout', handleMouseLeave);
            window.removeEventListener('mousemove', manageMouseMove)
            //stickyElement
            stickyElement.current.removeEventListener('mouseenter', manageMouseOver)
            stickyElement.current.removeEventListener('mouseleave', manageMouseLeave)

        }
    }, [stickyElement, cursorSize])



    return (
        <motion.div
            className='cursor'
            style={
                {
                    left: smoothMouse.x,
                    top: smoothMouse.y,
                    scaleX: scale.x,
                    scaleY: scale.y,
                    skewX: skew.x,
                    skewY: skew.y,
                    display: cursorVisible ? 'block' : 'none'
                }}
            animate={
                {
                    width: cursorSize,
                    height: cursorSize,
                    borderRadius: cursorBorderRadius
                }
            }

        >
        </motion.div>
    )
}

export default StickyCursor

