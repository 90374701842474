
import { useRef } from 'react';
import './App.css';
import Header from './components/Header';
import StickyCursor from './components/StickyCursor';

function App() {

  const stickyElement = useRef(null)

  return (
    <>  
      <Header ref={stickyElement} />
      <StickyCursor stickyElement={stickyElement} />
    </>
  );
}

export default App;
